<template>
  <v-sheet class="big-bar-sheet" elevation="2" :color="ui.primaryColor">
    <v-row class="align-center pa-0 ma-0">
      <v-col cols="5" class="mb-n10 pt-10 ps-5">
        <v-img
          :src="ui.squareLogoLink"
          class="rounded-xl shop-image border-0"
        />
      </v-col>
      <v-col cols="7" class="mb-n10 pt-10 ps-0 text-start">
        <h1 class="shop-name">
          {{ ui.name }}
        </h1>
      </v-col>
    </v-row>
  </v-sheet>
  <v-sheet class="pt-5 bg-transparent">
    <v-row class="align-center pa-0 ma-0">
      <v-col cols="7" class="ps-5">
        <p>{{ profile.displayName }}</p>
        <v-divider />
        <p>{{ profile.fullName }}</p>
        <router-link
          :to="{ name: 'membershipWalletTopUp' }"
          v-if="shopDetail.feature_enable.member_wallet"
          class="wallet-display"
        >
          <v-sheet
            :color="ui.primaryColor"
            class="pa-3 mt-3 d-flex"
            rounded="lg"
            elevation="2"
          >
            <v-icon icon="mdi-wallet-bifold-outline"></v-icon>
            <div class="ms-auto">{{ profile.walletAmount }} THB</div>
          </v-sheet>
        </router-link>
      </v-col>
      <v-col cols="5" class="pa-3 pe-5">
        <v-avatar
          icon="mdi-account"
          size="128"
          class="profile-image"
          :image="profile.pictureUrl"
          :color="ui.primaryColor"
        >
          <v-img v-if="profile.pictureUrl != null" :src="profile.pictureUrl" />
          <span v-if="profile.pictureUrl == null" class="text-h2">SS</span>
        </v-avatar>
      </v-col>
    </v-row>
  </v-sheet>
  <fund-carousel :color="ui.secondaryColor" :funds="profile.funds" />
  <v-divider class="mx-5" />
  <landing-menu-item
    icon="mdi-calendar-plus-outline"
    title="Book for a Class"
    description="จอง class เรียน"
    :to="{ name: 'classInfo', query: { memberId: $route.params.memberId } }"
    v-if="shopDetail.feature_enable.class_event"
  />
  <landing-menu-item
    icon="mdi-calendar-plus-outline"
    title="Book for Facility"
    description="จองใช้บริการ"
    :to="{ name: 'facilityInfo', query: { memberId: $route.params.memberId } }"
    v-if="shopDetail.feature_enable.facility_event"
  />
  <landing-menu-item
    icon="mdi-basket-outline"
    title="Purchase Package"
    description="ซื้อ package"
    :to="{ name: 'membershipPurchasePackage' }"
  />
  <landing-menu-item
    icon="mdi-history"
    title="Class History"
    description="ประวัติการใช้งาน class"
    :to="{ name: 'membershipHistory' }"
    v-if="shopDetail.feature_enable.class_event"
  />
  <landing-menu-item
    icon="mdi-history"
    title="Facility History"
    description="ประวัติการใช้งาน facility"
    :to="{ name: 'membershipFacilityHistory' }"
    v-if="shopDetail.feature_enable.facility_event"
  />
  <landing-menu-item
    icon="mdi-calendar-text"
    title="Upcoming"
    description="กำหนดการถัดไป"
    :to="{ name: 'membershipUpcoming' }"
    v-if="shopDetail.feature_enable.class_event"
  />
  <landing-menu-item
    icon="mdi-cog-outline"
    title="Settings"
    description="ตั้งค่าบัญชี"
    :to="{ name: 'membershipSetting' }"
  />
</template>

<script setup>
import { ref } from "vue";
import FundCarousel from "@/components/membership/FundCarousel.vue";
import LandingMenuItem from "@/components/membership/LandingMenuItem.vue";
import { useUi } from "@/util/ShopUi.js";
import { useProfile } from "@/util/MemberProfile.js";
import { useRoute } from "vue-router";
import BackendConnector from "@/util/BackendConnector";

const route = useRoute();
const ui = ref({});
const shopDetail = ref({ feature_enable: {} });

useUi(route.params.shopId).then((responseUi) => {
  ui.value = responseUi;
});
const profile = ref({ funds: [] });
useProfile(route.params.memberId).then((responseProfile) => {
  profile.value = responseProfile;
  console.log(profile);
});

BackendConnector.loadShopDetail(route.params.shopId).then((response) => {
  shopDetail.value = response.data;
});
</script>

<style scoped>
.shop-image {
  border: 5px solid white;
}

.shop-name {
  font-size: 1.5rem;
  font-weight: 600;
}

.profile-image {
  border: 5px solid white;
  font-size: 3.5rem;
}

.wallet-display {
  text-decoration: none !important;
}
</style>
